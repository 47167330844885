var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Einstellungen")]),_c('v-card',{staticClass:"mb-5"},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Umsatzsteuersatz","type":"number","error-messages":_vm.apiValidationErrors.tax,"disabled":_vm.loading || _vm.saving,"suffix":"%"},model:{value:(_vm.user_updates.tax),callback:function ($$v) {_vm.$set(_vm.user_updates, "tax", $$v)},expression:"user_updates.tax"}}),_c('v-text-field',{attrs:{"label":"Neuer Lexoffice Api-Schlüssel","error-messages":_vm.apiValidationErrors.lexoffice_key,"disabled":_vm.loading || _vm.saving,"persistent-hint":"","append-icon":_vm.user && _vm.user.lexoffice_connected ? 'delete' : null,"hint":"Hier können Sie einen neuen Api-Schlüssel generieren: <a target=\"_blank\" href=\"https://app.lexoffice.de/settings/#/public-api\" >https://app.lexoffice.de/settings/#/public-api</a >"},on:{"click:append":_vm.removeLexofficeConnection},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.user_updates.lexoffice_key),callback:function ($$v) {_vm.$set(_vm.user_updates, "lexoffice_key", $$v)},expression:"user_updates.lexoffice_key"}}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Angestrebte Stunden/Tag","error-messages":_vm.apiValidationErrors.target_daily_hours,"disabled":_vm.loading || _vm.saving,"persistent-hint":"","type":"number","hint":"Dies wird nur für die Darstellung in der Statistik genutzt."},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.user_updates.target_daily_hours),callback:function ($$v) {_vm.$set(_vm.user_updates, "target_daily_hours", $$v)},expression:"user_updates.target_daily_hours"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Angestrebte Einnahmen/Tag","error-messages":_vm.apiValidationErrors.target_daily_earnings,"disabled":_vm.loading || _vm.saving,"persistent-hint":"","suffix":"€","type":"number","hint":"Dies wird nur für die Darstellung in der Statistik genutzt."},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.user_updates.target_daily_earnings),callback:function ($$v) {_vm.$set(_vm.user_updates, "target_daily_earnings", $$v)},expression:"user_updates.target_daily_earnings"}})],1),_c('v-col',[_c('v-select',{attrs:{"disabled":_vm.loading || _vm.saving,"items":[
              { value: 1, text: 'Montag' },
              { value: 2, text: 'Dienstag' },
              { value: 3, text: 'Mittwoch' },
              { value: 4, text: 'Donnerstag' },
              { value: 5, text: 'Freitag' },
              { value: 6, text: 'Samstag' },
              { value: 7, text: 'Sonntag' }
            ],"label":"Ruhetage","multiple":""},model:{value:(_vm.user_updates.excluded_weekdays),callback:function ($$v) {_vm.$set(_vm.user_updates, "excluded_weekdays", $$v)},expression:"user_updates.excluded_weekdays"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","loading":_vm.saving},on:{"click":_vm.save}},[_vm._v(" Speichern ")])],1)],1),_c('v-card',[_c('v-card-title',[_vm._v("Konto löschen")]),_c('v-card-text',[_vm._v("Hier können Sie Ihr Zugangskonto unwiderruflich löschen.")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.deleteUser()}}},[_vm._v("Konto Löschen")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }